import React, { useState } from "react"
import Layout from "../../../components/layouts"
import Banner from "../../../components/features/Banner"
import SEO from "../../../components/common/SEO"
import ReactWOW from "react-wow"
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query SafetyMeetings {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: "Safety Meetings",
    subtitle: `Safety Meetings are an essential addition to your organization’s safety toolbox. They provide an opportunity to answer questions, address any hazards on a worksite, and discuss the proper ways to execute procedures. It’s also a great time to review unsafe habits or behaviors, take corrective action, and talk about what to do to in case of injuries, accidents or emergencies.`,
    featureName: "safetymeetings",
    rightButtonText: "Why Certificate Tracking Software?",
    rightButtonSrc: "https://www.youtube.com/watch?v=xE2yXvWB038",
    mockupImg: '/features/safety-meetings/feature-header-safetymeetings.png'
  }

  const seo = {
    title: "Workhub | Free Safety Meetings Software Toolbox Talks Safety Culture",
    description: "Our Safety Meetings tool makes it easy to schedule safety meetings, toolbox talks, send notifications & track & record attendance so that you can identify risks & promote safety culture in the workplace.",
    image: "/features/safety-meetings/social-card.jpg",
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Paper trail",
      para: "Create a digital paper trail of what is discussed at safety meetings and who was in attendance.",
    },
    {
      title: "Meeting Types",
      para: "Develop meeting types to serve as templates for meeting creation. Choose whether they are not applicable, optional, or mandatory.",
    },

    {
      title: "Supporting Documents",
      para: "Link any relevant procedures, inspections, hazards, and action items to help support the discussion.",
    },

    {
      title: "Set up",
      para: "Workers create meetings from meeting types and select dates, times, attendees, and can leave preliminary notes.",
    },
    {
      title: "Review",
      para: "An administrator can review the meeting (if specified) and approve. ",
    },
    {
      title: "Missed Meetings",
      para: "Missed meetings show up on the worker dashboard and meeting minutes will show up in the “Required” tab.",
    },
  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">
        <section className="feature-section">
          <div className="container">
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/safety-meetings/safety-meeting-cartoon3.jpg"
                    alt="Safety Meeting Cartoon"
                  />
                </ReactWOW>
              </div>

              <div className="col-lg-6 feature-text">
                <h2>
                  Combating Complacency
                </h2>
                <p>
                  Meeting regularly to discuss rules and guidelines keeps workers safe. One of the biggest benefits of a safety meeting is that they help combat complacency. When a procedure or job is done routinely, it can lead to a decrease in attention and that can make it more dangerous. Reminding workers of the correct safety steps is the best way to combat these moments.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 feature-text">
                <h2>Leave a paper trail</h2>

                <p>
                  Our Safety Meetings component allows you to create a digital paper trail of what has been discussed, and who was there to hear it. You can track attendance, pull up an electronic copy of the minutes, and create links to review any relevant procedures, inspections, hazards or action items. Keeping detailed records can help cover your company if liability is in question.
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInRight" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/safety-meetings/sketch-mockup-safety-meetings-admin-1.png"
                    alt="Safety Meeting Admin Computer View"
                  />
                </ReactWOW>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/safety-meetings/sketch-mockup-safety-meetings-admin-2.png"
                    alt="Safety Meeting Admin Computer View"
                  />
                </ReactWOW>
              </div>

              <div className="col-lg-6 feature-text">
                <h2>
                  Set up a Meeting
                </h2>

                <p>
                  Our safety meetings component allows for the creation of meeting types, which is intended to serve as a rough template. For each meeting type, you can choose whether it’s not applicable, optional, or mandatory (with frequency) for a given location. There are also settings for each meeting type, allowing you to pick whether people who missed the meeting are required to review it, and whether submissions should be automatically approved.
                </p>

              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 feature-text">
                <h2>
                  Keep your swans in a row
                </h2>

                <p>
                  To help with organizing these discussions you can link procedures, inspections, hazards, action items and include text. This will be useful for workers who missed the safety meeting but want to see what was discussed and what to review to continue their work safely. You can also pick a recommended start and end time, and briefly describe the purpose of the meeting type. At a glance, an administrator can also see which locations and meeting types are off schedule.
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-left"
                    src="/features/safety-meetings/sketch-mockup-safety-meetings-worker-1.png"
                    alt="Safety Meeting Worker Tablet View"
                  />
                </ReactWOW>
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="container">
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right"
                    src="/features/safety-meetings/sketch-mockup-safety-meetings-worker-2.png"
                    alt="Safety Meeting Worker Mobile View"
                  />
                </ReactWOW>
              </div>

              <div className="col-lg-6 feature-text">
                <h2>
                  Where the rubber meets the road
                </h2>

                <p>
                  Once a meeting type has been created, workers are able to go in and create a meeting! This means they can schedule a date and time, choose attendees, as well as input any preliminary notes they may have. When a meeting is complete, they simply click submit, and their work is done. If specified in the settings, an administrator would review the meeting, and approve it. If any workers have missed a meeting, they will see on their worker dashboard that they have a meeting to review. The meeting minutes will show up in the “Required” tab. Once a worker has read it over, they have the option to acknowledge.
                </p>

              </div>
            </div>
          </div>
        </section>

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>


      </main>
    </Layout>
  )
}
